import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import logo from './assets/LOGO_CROKIES_72_DPI.webp';
import perso from './assets/ClintIce_perso.webp';
//import './Accueil.css';

function Accueil() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const showInstallButton = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', showInstallButton);

    // Si l'application est en production, demandez la permission de notification
    if (process.env.NODE_ENV === 'production') {
      const requestNotificationPermission = async () => {
        try {
          await Notification.requestPermission();
        } catch (err) {
          console.error('Erreur lors de la demande de permission de notification :', err);
        }
      };

      requestNotificationPermission();
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', showInstallButton);
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('L\'utilisateur a accepté l\'installation');
        } else {
          console.log('L\'utilisateur a refusé l\'installation');
        }
      });
    }
  };

  useEffect(() => {
    // Initialisation de OneSignal uniquement en production
    if (process.env.NODE_ENV === 'production') {
      const OneSignal = window.OneSignal || [];
      OneSignal.push(() => {
        OneSignal.init({
          appId: '09d51046-4766-4fdf-8d8a-1852bcd09a6a', // Remplacez 'VOTRE_APP_ID' par votre ID d'application OneSignal
        });
      });
    }

    // Nettoyage des ressources
    return () => {
      // Destruction de OneSignal uniquement en production
      if (process.env.NODE_ENV === 'production') {
        const OneSignal = window.OneSignal || [];
        OneSignal.push(() => {
          OneSignal.removeEventListeners();
        });
      }
    };
  }, []);

  useEffect(() => {
    // Demande de permission de notification dès que l'utilisateur visite le site
    const requestNotificationPermission = async () => {
      try {
        await Notification.requestPermission();
      } catch (err) {
        console.error('Erreur lors de la demande de permission de notification :', err);
      }
    };

    requestNotificationPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      <Helmet>
      </Helmet>

     
      
      <header className="App-header gradient-background"> 
      <img src={logo} className="App-logo" alt="logo" />
      <img src={perso} className="animate__animated animate__zoomInUp" alt="logo" style={{ height: '200px', marginTop: '-250px', marginBottom: '35px' }} />


      </header>
      <div className="contentWrapper center-v">
       <h1 className='animate__animated animate__backInUp'>Bienvenue</h1>
       <h2>Scannez</h2>
        Vous devez scanner un QR code. <br /><br />
      </div>
      <footer>
        CROKIES ... C'EST WOOF'TASTIC !
      {deferredPrompt && (
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={handleInstall}>
              Installer
            </Button>
          }
          className="install-prompt"
        >
          Voulez-vous installer l'application ?
        </Alert>
      )}
      </footer>
    </div>
  );
}

export default Accueil;
