import React from 'react';
import { Helmet } from 'react-helmet';
import logo from './assets/LOGO_CROKIES_72_DPI.webp';
import prodPng from './assets/ClintIce_prod.webp';
import perso from './assets/ClintIce_perso.webp';


import FaqItem from './components/FaqItem/FaqItem';

import './ClintIce.css';

function ClintIce() {

  const faqs = [
    {
      question: "Quels sont les ingrédients à éviter dans l'alimentation des chiens pour maintenir leur santé et leur bien-être ?",
      answer: "Il est recommandé d'éviter les aliments pour chiens contenant des ingrédients tels que le maïs, le blé, le soja, les sous-produits de viande de qualité inférieure, les colorants et les conservateurs artificiels. Optez plutôt pour des aliments de haute qualité contenant des protéines de bonne source (viande, poisson), des glucides complexes (riz, patates douces), et des matières grasses saines (huile de poisson, huile de coco).",
    },
    {
      question: "Quelles sont les différences entre les aliments pour chiens à base de viande et ceux à base de céréales, et quelles sont les meilleures options pour la santé de mon chien ?",
      answer: "Les aliments pour chiens à base de viande ont tendance à fournir une source de protéines de meilleure qualité et sont plus adaptés à l'alimentation naturelle des chiens, car ils imitent davantage le régime ancestral des loups. Les aliments à base de céréales peuvent contenir des glucides simples et des ingrédients de moindre qualité. Les meilleures options pour la santé de votre chien sont les aliments de haute qualité contenant une proportion équilibrée de protéines, de glucides et de matières grasses, avec des ingrédients naturels et nutritifs.",
    },
    {
      question: "Comment puis-je savoir si l'alimentation actuelle de mon chien lui convient, et quels signes devrais-je surveiller pour détecter d'éventuels problèmes alimentaires ?",
      answer: "Pour déterminer si l'alimentation de votre chien lui convient, surveillez son niveau d'énergie, l'état de son pelage et de sa peau, la qualité de ses selles, et son comportement général. Des signes de problèmes alimentaires incluent des allergies cutanées, des démangeaisons, des vomissements fréquents, une perte ou un gain de poids soudain, une léthargie, ou des problèmes digestifs. Si vous remarquez ces symptômes, consultez votre vétérinaire pour obtenir des conseils sur l'alimentation de votre chien.",
    },
  ];
  

  return (
    <div className='ClintIce-page'>
      <Helmet>
      </Helmet>

     
      
      <header className="App-header gradient-background"> 
      <img src={logo} className="App-logo" alt="logo" />
      <div className="custom-shape-divider-bottom-1710954514">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
          </svg>
      </div>
      </header>
      <img src={prodPng} className="prodPng animate__animated animate__tada" alt="logo" />
      <div className="contentWrapper center-v">
        

       <h1 className='animate__animated animate__backInUp'>ClintEast'Dog - Porc</h1>
       <h2>Description</h2>
        Duck Party, le rafraichissement parfait au délicieux gout de Canard pour votre chien. <br /><br />

        Une préparation en poudre 100% naturelle, à glacer. Préparez-la simplement en y ajoutant de l'eau chaude et en la mettant au congélateur. 
        Sans Gluten 🌾 et Sans Lactose 🐮<br /><br />

        Nous l'avons enrichi d'un super aliment ; la betterave qui est riche en fibres, elle favorisera la bonne digestion. <br /><br />

        La Glace Crok'ice "Duck Party" est la friandise parfaite pour votre chien.<br /><br />

        Préparée en moins de 2 minutes, en plus de le régaler avec son délicieux goût de boeuf, elle stimule ses sens grâce au tapis de léchage.<br /><br />

        Elaborées en France dans nos ateliers Alsaciens 🇫🇷 🌱
      </div>
      <div className='questions'>
        <img src={perso} className="perso animate__animated animate__zoomInUp animate__delay-2s" alt="logo" />
        <div className="custom-shape-divider-top-1710969910">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
            </svg>
        </div>
        <h2>Question la plus demandée</h2>
        <div className='bigQuestion'>Quels sont les ingrédients à éviter dans l'alimentation des chiens pour maintenir leur santé et leur bien-être ?</div>
        <div className='bigReponse'>Il est recommandé d'éviter les aliments pour chiens contenant des ingrédients tels que le maïs, le blé, le soja, les sous-produits de viande de qualité inférieure, les colorants et les conservateurs artificiels. Optez plutôt pour des aliments de haute qualité contenant des protéines de bonne source (viande, poisson), des glucides complexes (riz, patates douces), et des matières grasses saines (huile de poisson, huile de coco).</div>
        <h2>Questions frequentes</h2>

        {/* Utilisez la liste de faqs pour afficher les questions fréquemment posées */}
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <FaqItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>

      </div>
      <footer>
        CROKIES ... C'EST WOOF'TASTIC !
      </footer>
    </div>
  );
}

export default ClintIce;
