import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NavBVar from './components/NavBar/NavBar';

import Accueil from './Accueil';
import DuckIce from './DuckIce';
import ClintIce from './ClintIce';
import NotFound from './NotFound';

import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Madimi+One&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Bevan:ital@0;1&display=swap" rel="stylesheet" />
      </Helmet>
        
      <NavBVar />
      <Router>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/DuckIce-10" element={<DuckIce />} />
          <Route path="/ClintIce-10" element={<ClintIce />} />
          <Route element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
