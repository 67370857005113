import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './FaqItem.css';

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="quetionFaq" onClick={toggleOpen}>
        {question}
        {isOpen ? <span>-</span> : <span><ArrowDropDownIcon /></span>}
      </div>
      {/* {isOpen && <div className="answerFaq">{answer}</div>} */}
      <div className={`answerFaq ${isOpen ? 'animate__animated animate__fadeInDown' : 'animate__animated animate__fadeOutUp'}`}>
        {isOpen && <div>{answer}</div>}
      </div>
    </div>
  );
}

export default FaqItem;
