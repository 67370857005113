import React from 'react';

import logo from './../../assets/LOGO_CROKIES_72_DPI.webp';
import { useState } from 'react'
import  styles from './Navbar.module.css';

function Navbar() {
    // adding the states 
    const [isActive, setIsActive] = useState(false);
    //add the active class
    const toggleActiveClass = () => {
      setIsActive(!isActive);
    };
    //clean up function to remove the active class
    const removeActive = () => {
      setIsActive(false)
    }
    return (

      <div className={`${styles.navbarWrapper}`}>
          <nav className={`${styles.navbar}`}>
          
            <a href='/' className={`${styles.logo}`}>
              <img src={logo} alt="logo" className={`${styles.logo}`}/>
              </a>
            <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
              <li onClick={removeActive}>
                <a href='/' className={`${styles.navLink}`}>Accueil</a>
              </li>
              <li onClick={removeActive}>
                <a href='/DuckIce-10/' className={`${styles.navLink}`}>DuckIce</a>
              </li>
              <li onClick={removeActive}>
                <a href='/ClintIce-10/' className={`${styles.navLink}`}>ClintIce</a>
              </li>
              {/* <li onClick={removeActive}>
                <a href='/livraison/' className={`${styles.navLink}`}>Livraison</a>
              </li>
              <li onClick={removeActive}>
                <a href='/qualite/' className={`${styles.navLink}`}>Qualité</a>
              </li>
              <li className={`${styles.ctaMenu}`}>Appelez nous 03 88 75 68 40</li> */}
            </ul>
            <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
              <span className={`${styles.bar}`}></span>
              <span className={`${styles.bar}`}></span>
              <span className={`${styles.bar}`}></span>
            </div>
          </nav>
        </div>

    );
  }
  export default Navbar;
  ;